<template>
  <div id="panel">
    <div class="overflow-hidden">
      <v-app-bar elevate-on-scroll scroll-target="#scrolling-techniques-7" height="70" :color="color" dark flat>
        <v-row>
          <v-col cols="3">
            <v-app-bar-nav-icon @click="toggleDrawer"></v-app-bar-nav-icon>
          </v-col>
          <v-col cols="6">
            <div class="main_logo">
              <img
                  class="mt-1"
                  width="40"
                  height="40"
                  src="https://upload.wikimedia.org/wikipedia/commons/a/ab/Android_O_Preview_Logo.png"
                  alt=""
              />
            </div>
          </v-col>
          <v-col cols="3">
            <v-menu right bottom>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on" style="text-align: left!important;">
                  <span class="ml-3" v-if="!mobile">{{ $store.state.user.name }}</span>
                  <v-avatar>
                    <img v-if="$store.state.user.avatar_url" class="user_avatar" width="30px" height="30px" :src="$store.state.user.avatar_url">
                    <img v-else class="user_avatar" width="30px" height="30px" src="../assets/defualt.jpeg">
                  </v-avatar>
                </div>
              </template>
              <v-list-item
                  class="bg_white"
                  link
                  to="/panel/profile"
              >
                <v-list-item-icon>
                  <v-icon>mdi-account</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>پروفایل</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                  class="bg_white"
                  link
                  @click="logOut"
              >
                <v-list-item-icon>
                  <v-icon>mdi-exit-to-app</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>خروج</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-menu>
          </v-col>
        </v-row>
      </v-app-bar>

      <v-sheet
          id="scrolling-techniques-7"
          class="overflow-y-auto"
          style="max-height: calc(100vh - 70px)"
      >
        <sidebar ref="sidebarComponent"></sidebar>
        <div :class="mobile ? '' : 'mr-14'">
          <v-container fluid>
            <router-view></router-view>
          </v-container>
        </div>
      </v-sheet>
    </div>
  </div>
</template>
<script>
import Sidebar from "../components/Sidebar";

export default {
  name: "Panel",
  components: {Sidebar},
  data() {
    return {
      color: "#161853",
      userMenu: [
        {title: "پروفایل", icon: "mdi-account", route: '/panel/profile'},
        {title: "خروج", icon: "mdi-exit-to-app", route: '/panel/exit'},
      ],
    };
  },
  methods: {
    toggleDrawer() {
      this.$refs.sidebarComponent.toggleDrawer()
    },
    logOut() {
      window.axios.get('/user/logout').then(
          () => {
            this.$store.commit('logout');
            this.$router.push('/login');
          }
      )
    }
  }
};
</script>
<style scoped>
.main_logo {
  width: 100%;
}

.main_logo img {
  display: block;
  margin: 0 auto;
}

.user_name {
  font-size: 12px;
}

.bg_white {
  background: rgba(255, 255, 255, 0.9);
}

.v-list-item__title {
  font-size: 12px;
}
</style>