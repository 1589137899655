<template>
<div>
  <v-navigation-drawer
      v-model="drawer"
      :color="color"
      :expand-on-hover="!mobile"
      :permanent="!mobile"
      :temporary="mobile"
      :bottom="mobile"
      absolute
      dark
      right
  >
    <v-list nav class="bg-white py-0" :style="{ marginTop: !mobile ? '70px' : '10px' }">
      <v-list-item v-for="(item, index) in sidebarMenu" :key="item.title"
                   link :to="item.route" :class="index + 1 === sidebarMenu.length && !mobile ? 'api_doc' : ''">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</div>
</template>

<script>
export default {
  name: "Sidebar",
  data() {
    return {
      drawer: false,
      sidebarMenu: [
        { title: "ویدیو", icon: "mdi-video", route: '/panel/video' },
        { title: "فریم ها", icon: "mdi-image-multiple", route: '/panel/frame' },
        { title: "تصویر", icon: "mdi-image", route: '/panel/image' },
        { title: "پلن های شما", icon: "mdi-account", route: '/panel/my-plans' },
        { title: "پلن ها", icon: "mdi-dropbox", route: '/panel/plans' },
        { title: "سفارشات", icon: "mdi-lifebuoy", route: '/panel/orders' },
        { title: "استریم", icon: "mdi-video-wireless", route: '/panel/meeting' },
        { title: "مستندات", icon: "mdi-api", route: '/panel/document' },
      ],
      color: "#161853",
    }
  },
  watch: {
    mobile: function (val) {
      if (val === true) {
        this.drawer = false
      }
    }
  },
  methods: {
    toggleDrawer() {
      this.drawer = !this.drawer
    },
  },
}
</script>

<style scoped>
.v-icon.v-icon, .v-list .v-list-item--active .v-icon, .v-list-item__title{
  color: #FAEDF0;
}
.api_doc{
  position: absolute;
  bottom: 10px;
  left: 6px;
  right: 6px;
}
.v-navigation-drawer--close {
  display: none!important;
}
</style>